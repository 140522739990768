import React from "react";

const NewsArticleDate: React.FC<{
  date: string;
  modified?: string;
}> = ({ date, modified }) => {
  const articleDate = new Date(date).toLocaleDateString("en-US", {
    dateStyle: "long",
  });

  let articleModifiedDescriptor;

  if (modified) {
    const articleDateAsString = new Date(modified).toLocaleDateString("en-US", {
      dateStyle: "long",
    });

    if (articleDateAsString !== articleDate) {
      articleModifiedDescriptor = (
        <span>&#183; Last updated {articleDateAsString}</span>
      );
    }
  }

  return (
    <span className="text-xs flex flex-wrap">
      <span className="mr-xs" data-testid="article-publish-date">
        Published on {articleDate}
      </span>{" "}
      {articleModifiedDescriptor}
    </span>
  );
};

export default NewsArticleDate;
