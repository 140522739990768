import React from "react";
import Link from "next/link";

import { Hero } from "custom-types/Wordpress";

import Image from "components/Image";
import NewsSocialShareBar from "components/news/NewsSocialShareBar";

type Props = {
  hero: Hero;
  title: string;
  link: string;
  showTitle?: boolean;
};

const PageHero: React.FC<Props> = ({ hero, title, link, showTitle }) => {
  const {
    backgroundColor,
    ctaText,
    desktopImage,
    enable,
    ctaLink,
    mobileImage,
    subtitle,
    tabletImage,
    textColor,
    alternateTitle,
  } = hero;

  const hasImage = desktopImage || tabletImage || mobileImage;

  return enable ? (
    <div className="relative" style={{ backgroundColor, height: 420 }}>
      {showTitle && (
        <div className="container pt-xl pb-lg md:pt-xxl md:pb-xl flex flex-col justify-center h-full">
          <div
            className="w-full z-10 flex flex-col items-start text-left"
            style={{ color: textColor, maxWidth: 450 }}
          >
            <h1 className="heading--hero">{alternateTitle || title}</h1>
            {subtitle && <p className="m-none mt-xs text-sm">{subtitle}</p>}
            {ctaLink && ctaText && (
              <Link className="my-xl button px-section" href={ctaLink}>
                {ctaLink}
              </Link>
            )}
            <NewsSocialShareBar link={link} title={title} className="mt-sm" />
          </div>
        </div>
      )}
      {hasImage && (
        <Image
          alt=""
          background={true}
          disableLazyLoad={true}
          src={desktopImage}
          tabletSrc={tabletImage}
          mobileSrc={mobileImage}
          sizes={[544, 767, 1023, 1440, null, 767]}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default PageHero;
