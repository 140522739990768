import NewsAd from "./NewsAd";

export default ({
  targeting,
}: {
  targeting?: Record<string, string | string[] | undefined>;
}) => (
  <NewsAd
    targeting={targeting}
    unit="/parallax"
    className="gam-square-fixed-height"
    sizes={{
      desktop: [300, 250],
      mobile: [300, 250],
    }}
  />
);
