import { FC, PropsWithChildren, useMemo } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";

import {
  AuthorPage,
  Breadcrumb as BreadcrumbType,
  MenuItems,
  Page,
  Taxonomy,
} from "custom-types/Wordpress";
import createNewsTargetingObject from "utils/createNewsTargetingObject";
import parseHtmlToString from "utils/parseHtmlToString";
import slugifyName from "utils/slugifyName";

import Breadcrumb from "components/Breadcrumb";
import Breadcrumbs from "components/Breadcrumbs";
import PageHero from "components/PageHero";

import NewsAd from "../Ads/NewsAd";
import NewsAdBillboard from "../Ads/NewsAdBillboard";
import NewsAdLower from "../Ads/NewsAdLower";
import NewsArticleDate from "../NewsArticleDate";
import NewsRequiredContainer from "../NewsRequiredContainer";
import NewsSideBar from "../NewsSideBar";
import NewsSocialShareBar from "../NewsSocialShareBar";

type LayoutProps = PropsWithChildren<{
  menuItems?: MenuItems;
  disableAds?: boolean;
  showSidebar?: boolean;
  adTargeting?: Record<string, string | string[] | undefined>;
}>;

const DefaultLayout: FC<LayoutProps> = ({
  menuItems,
  disableAds = false,
  children,
  adTargeting,
}) => (
  <div className="container mb-section mt-sm" data-testid="default-layout">
    <div className="row">
      <div className="col md:col-3">
        <NewsSideBar
          menuItems={menuItems}
          disableAds={disableAds}
          adTargeting={adTargeting}
        />
      </div>
      <div className="col md:col-9">{children}</div>
    </div>
  </div>
);

const CenteredLayout: FC<LayoutProps> = ({ children }) => (
  <div className="container mb-section mt-sm" data-testid="centered-layout">
    {children}
  </div>
);

const FullWidthLayout: FC<LayoutProps> = ({
  menuItems,
  disableAds = false,
  children,
  showSidebar = false,
  adTargeting,
}) => (
  <div
    className={classNames("mb-section mt-sm", {
      "full-width-sidebar": showSidebar,
    })}
    data-testid="fullwidth-layout"
  >
    {showSidebar && (
      <div className="container relative" data-testid="with-sidebar">
        <div className="md:absolute">
          <NewsSideBar
            menuItems={menuItems}
            disableAds={disableAds}
            adTargeting={adTargeting}
          />
        </div>
      </div>
    )}
    {children}
  </div>
);

type BreadcrumbsType = { breadcrumbs: BreadcrumbType[] };
type PageProps = LayoutProps & {
  page: Page | (Taxonomy & BreadcrumbsType) | (AuthorPage & BreadcrumbsType);
} & { currentPage?: number; totalPages?: number };

const PageLayout: FC<PageProps> = ({
  children,
  page,
  currentPage,
  totalPages,
  ...props
}) => {
  const {
    query: { slugs = [] },
  } = useRouter();

  const Layout = useMemo(() => {
    switch ((page as Page)?.layout?.contentWidth) {
      case "centered":
        return CenteredLayout;
      case "fullwidth":
        return FullWidthLayout;
      default:
        return DefaultLayout;
    }
  }, [(page as Page)?.layout?.contentWidth]);

  const primaryCategorySlug = (page as Page)?.categories?.length
    ? slugifyName((page as Page)?.categories[0])?.replace("--", "-")
    : slugs.length
      ? slugs[0]
      : undefined;

  const adTargeting = createNewsTargetingObject(
    page.slug,
    primaryCategorySlug,
    (page as Page).tags,
  );

  return (
    <>
      <NewsRequiredContainer
        styles={(page as Page)?.styles}
        scripts={(page as Page)?.scripts}
        id={page.id}
        regionRestriction={(page as Page)?.regionRestriction}
        seoHeadContent={page.seoHeadContent}
        primaryCategorySlug={primaryCategorySlug}
        title={page.title}
        categories={(page as Page).categories}
        authorName={(page as Page).author?.name}
        schemaJson={(page as Page)?.schemaJson}
        tags={(page as Page)?.tags}
        slug={page.slug}
        currentPage={currentPage}
        totalPages={totalPages}
      >
        {page.breadcrumbs.length > 0 && (
          <Breadcrumbs className="mt-lg">
            {page.breadcrumbs.map((crumb, i) => (
              <Breadcrumb
                href={crumb.href || undefined}
                currentLocation={i === page.breadcrumbs.length - 1}
                key={`news-breadcrumb-${i}`}
              >
                {parseHtmlToString(crumb.title)}
              </Breadcrumb>
            ))}
          </Breadcrumbs>
        )}
        {!(page as Page).disableAds && (
          <NewsAdBillboard targeting={adTargeting} />
        )}
        {(page as Page).hero?.enable && (
          <PageHero
            hero={(page as Page).hero}
            title={page.title}
            link={page.link}
            showTitle={(page as Page).layout?.showTitle}
          />
        )}

        <Layout
          {...props}
          disableAds={(page as Page).disableAds}
          showSidebar={(page as Page).layout?.showSidebar}
          adTargeting={adTargeting}
        >
          {!(page as Page).hero?.enable && (page as Page).layout?.showTitle && (
            <div id="news-header-container" className="container mb-lg">
              <h1 dangerouslySetInnerHTML={{ __html: page.title }}></h1>
              <NewsArticleDate
                date={(page as Page).date || ""}
                modified={(page as Page).modified}
              />
              <NewsSocialShareBar
                link={page.link}
                title={page.title}
                className="mt-md"
              />
            </div>
          )}
          {children}
          {!(page as Page).disableAds && !(page as Page).manualAdPlacement && (
            <NewsAdLower targeting={adTargeting} />
          )}
        </Layout>
      </NewsRequiredContainer>
      {!(page as Page).disableAds ? (
        <NewsAd
          targeting={adTargeting}
          unit="/outofpage"
          sizes={{ desktop: [1, 1], mobile: [1, 1] }}
          className="sticky"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default PageLayout;
