import NewsAdAdditional from "components/news/Ads/NewsAdAdditional";
import NewsAdParallax from "components/news/Ads/NewsAdParallax";
import NewsAdUpper from "components/news/Ads/NewsAdUpper";

export const AdElement = ({
  adNumber,
  targeting,
  enableLazyLoad,
}: {
  adNumber: number;
  targeting?: Record<string, string | string[] | undefined>;
  enableLazyLoad?: boolean;
}) => {
  const isAdditional = adNumber > 2;
  const AdUnit = getAdUnit(adNumber, isAdditional, targeting, enableLazyLoad);

  return AdUnit || <></>;
};

export const getAdUnit = (
  adNumber: number,
  isAdditional: boolean,
  targeting?: Record<string, string | string[] | undefined>,
  enableLazyLoad?: boolean,
) => {
  if (isAdditional) {
    // Because the additional ads come into play after the 2nd ad, and the
    // additional ads start with 1 (ex: News_Article_1), we need to make sure
    // the index for the additional ads start at 1
    return (
      <NewsAdAdditional
        index={adNumber - 2}
        targeting={targeting}
        enableLazyLoad={enableLazyLoad}
      />
    );
  }
  if (adNumber === 1) {
    return <NewsAdUpper targeting={targeting} />;
  }
  if (adNumber === 2) {
    return <NewsAdParallax targeting={targeting} />;
  }
};
